import React from 'react';
import './Header.css';
import { toggleSideMenu } from '../actions/app';
import { connect } from 'react-redux';
import MenuIcon from './MenuIcon';
import CloseIcon from './CloseIcon';
import { history } from '../store';
import ReturnIcon from './ReturnIcon';

function HeaderLeft({ pathname, showSideMenu, toggleSideMenu, setShowLogin, setShowContact, showLogin, showContact, logoColor }) {
  return (
    <div className={'Header HeaderLeft'}>
      <div>
        <div
          className={'LogoContainer'}
          onClick={() => {
            history.push('/');
            // setShowLogin(false)
            // setShowContact(false)
          }}
        >
          <img className={'Logo'} src={pathname === '/products' ? (logoColor === 'light' ? '/logo-light.png' : '/logo2.png') : '/logo.png'} />
        </div>
        {/*<div className={"MenuContainer"}>*/}
        {/*<svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg">*/}
        {/*  <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
        {/*    <g id="主页备份-3" transform="translate(-1035.000000, -27.000000)" stroke="#6F6F6F" strokeWidth="0.8">*/}
        {/*      <g id="Group-2" transform="translate(53.947152, 23.179999)">*/}
        {/*        <g id="Group" transform="translate(981.052848, 0.820001)">*/}
        {/*          <g id="编组" transform="translate(0.000000, 3.000000)">*/}
        {/*            <circle id="椭圆形" cx="6" cy="6" r="5.6"></circle>*/}
        {/*            <path*/}
        {/*              d="M9.39746094,10.4262695 C8.82775879,9.82531738 12.6147461,13.65 12.6147461,13.65 C12.6147461,13.65 8.82775879,9.82531738 9.39746094,10.4262695 Z"*/}
        {/*              id="路径-7"/>*/}
        {/*          </g>*/}
        {/*        </g>*/}
        {/*      </g>*/}
        {/*    </g>*/}
        {/*  </g>*/}
        {/*</svg>*/}
        {/*<span>了解产品</span>*/}
        {/*  <span*/}
        {/*    className={showContact ? "Active" : ""}*/}
        {/*    onClick={() => {*/}
        {/*      setShowLogin(false)*/}
        {/*      setShowContact(true)*/}
        {/*    }}>联系我们</span>*/}
        {/*  <svg width="1px" height="11px" viewBox="0 0 1 14" version="1.1" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
        {/*      <g id="主页备份-3" transform="translate(-1192.000000, -26.000000)" stroke="#6F6F6F" strokeWidth="0.8">*/}
        {/*        <g id="Group-2" transform="translate(53.947152, 23.179999)">*/}
        {/*          <g id="Group" transform="translate(981.052848, 0.820001)">*/}
        {/*            <line x1="157.5" y1="2" x2="157.5" y2="15.8" id="路径-6"/>*/}
        {/*          </g>*/}
        {/*        </g>*/}
        {/*      </g>*/}
        {/*    </g>*/}
        {/*  </svg>*/}
        {/*  <span*/}
        {/*    className={showLogin ? "Active" : ""}*/}
        {/*    onClick={() => {*/}
        {/*      setShowContact(false)*/}
        {/*      setShowLogin(true)*/}
        {/*    }}>登录</span>*/}
        {/*  /!*<span to={"/"}>了解产品</span>*!/*/}
        {/*  <span className={"lang langFirst"}>中文</span><span className={"lang"}>/</span><span*/}
        {/*  className={"lang langLast"}>EN</span>*/}
        {/*</div>*/}
        {/*<div className={"MenuLogo"}>*/}
        {/*  <svg width="17px" height="13px" viewBox="0 0 17 13" version="1.1" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">*/}
        {/*      <g id="主页备份-2" transform="translate(-55.000000, -89.000000)" stroke="#000000">*/}
        {/*        <g id="Group" transform="translate(56.000000, 89.000000)">*/}
        {/*          <line x1="-4.88448567e-13" y1="0.5" x2="15.1428571" y2="0.5" id="直线"/>*/}
        {/*          <line x1="-4.51136896e-13" y1="6.5" x2="15.1428571" y2="6.5" id="直线备份"/>*/}
        {/*          <line x1="-4.51136896e-13" y1="12.5" x2="15.1428571" y2="12.5" id="直线备份-2"/>*/}
        {/*        </g>*/}
        {/*      </g>*/}
        {/*    </g>*/}
        {/*  </svg>*/}
        {/*</div>*/}
      </div>

      {/*{pathname !== '/products' && (*/}
      <div className={`MenuIcon ${showSideMenu ? 'Show' : ''}`}>
        {!showSideMenu && (
          <MenuIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleSideMenu();
            }}
          />
        )}
        {showSideMenu && (
          <ReturnIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleSideMenu();
            }}
          />
        )}
      </div>
      {/*)}*/}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    showSideMenu: state.app.showSideMenu,
    lang: state.app.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSideMenu: () => dispatch(toggleSideMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLeft);
